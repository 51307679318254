import { Text } from '@lego/klik-ui';
import { TableViewer } from '../../components/TableViewer';
import { createColumnHelper } from '@tanstack/react-table';
import { Quota } from '@ta-global-kiosk/domain';
import { useAuthData } from '../../contexts/AuthProvider';
import { useEffect, useMemo, useState } from 'react';
import { getDataByManager } from '../../utils/data/time';
import { useTranslation } from 'react-i18next';
import { Periode, PeriodeCarousel } from '../../components/PeriodeCarousel';
import { getPeriodes } from '../../utils/datetime';

const columnHelper = createColumnHelper<Quota>();

export const EmployeeQuotas = () => {
  const { instance } = useAuthData();
  const { t } = useTranslation();

  const columns = useMemo(() => {
    return getColumns(t);
  }, [t]);

  const periodes = useMemo(() => {
    return getPeriodes();
  }, []);

  const [data, setData] = useState<Quota[]>([]);
  const [period, setPeriod] = useState(periodes[periodes.length - 1]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const abortController = new AbortController();
    const getData = async () => {
      setIsLoading(true);
      try {
        const data = await getDataByManager(
          instance,
          'quotas',
          new Date(period.start),
          new Date(period.end),
          abortController,
        );
        setData(data);
      } catch (e: any) {
        if (e.name !== 'AbortError') {
          console.error(e);
        }
      } finally {
        setIsLoading(false);
      }
    };

    getData();

    return () => {
      abortController.abort();
    };
  }, [instance, period]);

  return (
    <>
      <Text as='h1' textStyle='h1' mt={5}>
        Quotas for your direct reports for
      </Text>
      <PeriodeCarousel
        periodes={periodes}
        initialSelection={periodes.length - 1}
        onSelectionChange={(period: Periode) => setPeriod(period)}
      />
      <TableViewer data={data} columns={columns} isLoading={isLoading}></TableViewer>
    </>
  );
};

const getColumns = (t: any) => {
  return [
    columnHelper.accessor('employeeName', {
      header: () => t('Quota.employee'),
      minSize: 200,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('employeeNumber', {
      header: () => t('Quota.employee_number'),
      maxSize: 130,
      cell: (info) => info.getValue().replace(/^0+/, ''),
    }),
    columnHelper.accessor('sapId', {
      header: () => t('Quota.sap_id'),
      maxSize: 130,
      cell: (info) => info.getValue().replace(/^0+/, ''),
    }),
    columnHelper.accessor('name', {
      cell: (info) => info.getValue(),
      maxSize: 130,
      header: () => t('Quota.name'),
    }),
    columnHelper.accessor('type', {
      header: () => t('Quota.type'),
      maxSize: 100,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('daysRemaining', {
      header: () => t('Quota.remaining'),
      maxSize: 100,
      cell: (info) => info.getValue().toFixed(2),
    }),
    columnHelper.accessor('daysUsed', {
      header: () => t('Quota.used'),
      maxSize: 100,
      cell: (info) => info.getValue().toFixed(2),
    }),
    columnHelper.accessor('daysEarned', {
      header: () => t('Quota.earned'),
      maxSize: 100,
      cell: (info) => info.getValue().toFixed(2),
    }),
    columnHelper.accessor('startDate', {
      header: () => t('Quota.from'),
      maxSize: 100,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('endDate', {
      header: () => t('Quota.to'),
      maxSize: 100,
      cell: (info) => info.getValue(),
    }),
  ];
};
