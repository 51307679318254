import { Periode } from '../components/PeriodeCarousel';

/**
 *@param dateString expected format '/Date(d+)/'
 */
export const parseDate = (dateString: string | undefined): Date | undefined => {
  if (!dateString || dateString === '' || dateString === undefined) {
    return undefined;
  }
  let result;
  if (dateString.startsWith('/Date')) {
    const milliSeconds = Number(dateString.replace('/Date(', '').replace(')/', ''));
    result = new Date(milliSeconds);
  } else {
    result = new Date(dateString);
  }
  return result;
};

export const formatLocalFromUTC = (date?: Date) => {
  if (date) {
    return date.toLocaleDateString(undefined, { timeZone: 'UTC' });
  }
  return '';
};

export const formatToDateString = (date: Date) => {
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
    .getDate()
    .toString()
    .padStart(2, '0')}`;
};

export const generateWeekPeriods = (
  numberOfWeeks: number,
  excludeCurrentWeek?: boolean,
): Periode[] => {
  const weeks = generateWeeks(numberOfWeeks, excludeCurrentWeek);
  return weeks.map((week) => {
    const [start, end] = week.split('#');
    return {
      id: week,
      display: `${start} - ${end}`,
      start,
      end,
    };
  });
};

export const generateWeeks = (numberOfWeeks: number, excludeCurrentWeek?: boolean): string[] => {
  let weeks: string[] = [];
  let currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  // Get the number of days to the previous Monday
  let daysToLastMonday = (currentDate.getDay() + 6) % 7;
  if (excludeCurrentWeek) {
    daysToLastMonday = daysToLastMonday + 7;
  }

  for (let i = 0; i < numberOfWeeks; i++) {
    let weekStart = new Date(
      currentDate.getTime() - (i * 7 + daysToLastMonday) * 24 * 60 * 60 * 1000,
    );
    let weekEnd = new Date(weekStart.getTime() + 6 * 24 * 60 * 60 * 1000);

    let weekStartString = formatToDateString(weekStart);
    let weekEndString = formatToDateString(weekEnd);

    weeks.push(`${weekStartString}#${weekEndString}`);
  }

  return weeks;
};

export const generateSemiMonthPeriods = (
  numberOfMonths: number,
  excludeCurrentPeriode?: boolean,
): Periode[] => {
  const semiMonths = generateSemiMonths(numberOfMonths, excludeCurrentPeriode);
  return semiMonths.map((semiMonth) => {
    const [start, end] = semiMonth.split('#');
    return {
      id: semiMonth,
      display: `${start} - ${end}`,
      start,
      end,
    };
  });
};

export const generateSemiMonths = (
  numberOfMonths: number,
  excludeCurrentPeriode?: boolean,
): string[] => {
  let semiMonths: string[] = [];
  let currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  let sliceStart = excludeCurrentPeriode ? 1 : 0;
  let currentMonth = currentDate.getMonth();
  let currentYear = currentDate.getFullYear();

  let monthStart = new Date(currentYear, currentMonth, 1);
  let monthEnd = new Date(currentYear, currentMonth, 15);

  let monthStartString = formatToDateString(monthStart);
  let monthEndString = formatToDateString(monthEnd);

  semiMonths.push(`${monthStartString}#${monthEndString}`);
  if (currentDate.getDate() > 15) {
    monthStart = new Date(currentYear, currentMonth, 16);
    monthEnd = new Date(currentYear, currentMonth + 1, 0);

    monthStartString = formatToDateString(monthStart);
    monthEndString = formatToDateString(monthEnd);

    semiMonths.push(`${monthStartString}#${monthEndString}`);
  }

  for (let i = 0; i < numberOfMonths; i++) {
    let monthStart = new Date(currentYear, currentMonth - (i + 1), 1);
    let monthEnd = new Date(currentYear, currentMonth - (i + 1), 15);

    let monthStartString = formatToDateString(monthStart);
    let monthEndString = formatToDateString(monthEnd);

    semiMonths.push(`${monthStartString}#${monthEndString}`);

    monthStart = new Date(currentYear, currentMonth - (i + 1), 16);
    monthEnd = new Date(currentYear, currentMonth - (i + 2), 0);

    monthStartString = formatToDateString(monthStart);
    monthEndString = formatToDateString(monthEnd);

    semiMonths.push(`${monthStartString}#${monthEndString}`);
  }

  return semiMonths.slice(sliceStart, sliceStart + numberOfMonths * 2);
};

export const generateYearPeriods = (numberOfYears: number): Periode[] => {
  const years = generateYears(numberOfYears);
  return years.map((year) => {
    return {
      id: `${year}`,
      display: `${year}`,
      start: `${year}-01-01`,
      end: `${year}-12-31`,
    };
  });
};

export const generateYears = (numberOfYears: number): number[] => {
  let years: number[] = [];
  for (let i = 0; i < numberOfYears; i++) {
    years.push(new Date().getFullYear() - i);
  }
  return years;
};

export const generateDaysInWeek = (week: string): string[] => {
  const [start] = week.split('#');
  const startDate = new Date(start);
  const days: string[] = [];
  for (let i = 0; i <= 6; i++) {
    const date = new Date(startDate.getTime() + i * 24 * 60 * 60 * 1000);
    days.push(formatToDateString(date));
  }
  return days;
};

export const getPeriodes = (): Periode[] => {
  const date = new Date();
  const currentYear = date.getFullYear();
  const startYear = 2023;
  const periods: Periode[] = [];

  for (let year = startYear; year <= currentYear; year++) {
    periods.push({
      id: year.toString(),
      display: year.toString(),
      start: `${year}-01-01`,
      end: year === currentYear ? date.toISOString().split('T')[0] : `${year}-12-31`,
    });
  }

  return periods;
};
